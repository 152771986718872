var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            inline: "",
            "label-position": "right",
            "label-width": "80px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "请选择司机" },
                  model: {
                    value: _vm.form.deliver_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "deliver_id", $$v)
                    },
                    expression: "form.deliver_id",
                  },
                },
                _vm._l(_vm.driverSelect, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "出货仓库" },
                  model: {
                    value: _vm.form.depot_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "depot_id", $$v)
                    },
                    expression: "form.depot_id",
                  },
                },
                _vm._l(_vm.depotSelect, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "订单状态" },
                  model: {
                    value: _vm.form.bill_status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "bill_status", $$v)
                    },
                    expression: "form.bill_status",
                  },
                },
                _vm._l(_vm.orderStatus, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "调拨状态" },
                  model: {
                    value: _vm.form.allot_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "allot_type", $$v)
                    },
                    expression: "form.allot_type",
                  },
                },
                _vm._l(_vm.transfersStatus, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "下单时间" },
                  model: {
                    value: _vm.form.time_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "time_type", $$v)
                    },
                    expression: "form.time_type",
                  },
                },
                _vm._l(_vm.orderTime, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "datetimerange",
                  "range-separator": "-",
                  "default-time": ["00:00:00", "23:59:59"],
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                },
                model: {
                  value: _vm.time,
                  callback: function ($$v) {
                    _vm.time = $$v
                  },
                  expression: "time",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: _vm.btnIndex == 1 ? "primary" : "" },
                  on: { click: _vm.handleGoodsSummary },
                },
                [_vm._v(" 已派商品汇总 ")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: _vm.btnIndex == 2 ? "primary" : "" },
                  on: { click: _vm.handleOrderSummary },
                },
                [_vm._v(" 查询已派订单 ")]
              ),
            ],
            1
          ),
          _vm.btnIndex == 1
            ? _c(
                "el-form-item",
                { attrs: { label: "", prop: "" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "warning" },
                      on: {
                        click: function ($event) {
                          return _vm.handleExport(1)
                        },
                      },
                    },
                    [_vm._v(" 导出商品汇总 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "warning" },
                      on: { click: _vm.handleExportClient },
                    },
                    [_vm._v(" 导出客户汇总 ")]
                  ),
                ],
                1
              )
            : _c(
                "el-form-item",
                { attrs: { label: "", prop: "" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "warning" },
                      on: {
                        click: function ($event) {
                          return _vm.handleExport(2)
                        },
                      },
                    },
                    [_vm._v(" 导出订单汇总 ")]
                  ),
                ],
                1
              ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.btnIndex == 1,
                  expression: "btnIndex == 1",
                },
              ],
              staticClass: "goods",
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    "margin-bottom": "10px",
                    "font-size": "20px",
                    "font-weight": "600",
                    "text-align": "center",
                  },
                },
                [
                  _vm.time
                    ? _c("span", [
                        _vm._v(
                          _vm._s(_vm.time[0]) + " 至 " + _vm._s(_vm.time[1])
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" 预订单汇总 "),
                ]
              ),
              _c("div", { staticClass: "tip" }, [
                _c("div", { staticClass: "dirver" }, [
                  _vm._v("司机:" + _vm._s(_vm.info.user_name)),
                ]),
                _c("div", { staticClass: "car" }, [
                  _vm._v("车辆:" + _vm._s(_vm.info.car_name)),
                ]),
                _c("div", { staticClass: "order-count" }, [
                  _vm._v("订单数量:" + _vm._s(_vm.info.order_count)),
                ]),
                _c("div", { staticClass: "order-count" }, [
                  _vm._v("总金额:" + _vm._s(_vm.info.total_amount)),
                ]),
                _c("div", { staticClass: "money" }, [
                  _vm._v("已收金额:" + _vm._s(_vm.info.already_amount)),
                ]),
                _c("div", { staticClass: "wait-money" }, [
                  _vm._v("待收金额:" + _vm._s(_vm.info.left_amount)),
                ]),
              ]),
              _c(
                "el-table",
                {
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: {
                    stripe: "",
                    data: _vm.list,
                    "show-summary": "",
                    "summary-method": _vm.handlersummary,
                  },
                },
                _vm._l(_vm.finallyColumns, function (item, tableIndex) {
                  return _c("el-table-column", {
                    key: tableIndex,
                    attrs: {
                      prop: item.prop,
                      label: item.label,
                      width: "",
                      align: "center",
                    },
                  })
                }),
                1
              ),
              _c(
                "el-table",
                { attrs: { stripe: "", data: _vm.custList } },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "cust_name",
                      label: "客户名称",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "address", label: "地址", align: "center" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "left_amount",
                      label: "待收金额",
                      align: "center",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "bottom" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleOrderSummary },
                    },
                    [_vm._v(" 查看订单汇总 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleprint },
                    },
                    [_vm._v(" 打印 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.btnIndex == 2,
                  expression: "btnIndex == 2",
                },
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "order",
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    "margin-bottom": "10px",
                    "font-size": "20px",
                    "font-weight": "600",
                    "text-align": "center",
                  },
                },
                [
                  _vm.time
                    ? _c("span", [
                        _vm._v(
                          _vm._s(_vm.time[0]) + " 至 " + _vm._s(_vm.time[1])
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" 预订单汇总 "),
                ]
              ),
              _c("div", { staticClass: "tip" }, [
                _c("div", { staticClass: "dirver" }, [
                  _vm._v("司机:" + _vm._s(_vm.info.user_name)),
                ]),
                _c("div", { staticClass: "car" }, [
                  _vm._v("车辆:" + _vm._s(_vm.info.car_name)),
                ]),
                _c("div", { staticClass: "order-count" }, [
                  _vm._v("订单数量:" + _vm._s(_vm.info.order_count)),
                ]),
                _c("div", { staticClass: "order-count" }, [
                  _vm._v("总金额:" + _vm._s(_vm.info.total_amount)),
                ]),
                _c("div", { staticClass: "money" }, [
                  _vm._v("已收金额:" + _vm._s(_vm.info.already_amount)),
                ]),
                _c("div", { staticClass: "wait-money" }, [
                  _vm._v("待收金额:" + _vm._s(_vm.info.left_amount)),
                ]),
              ]),
              _c(
                "el-table",
                {
                  staticStyle: { "margin-bottom": "10px" },
                  attrs: { stripe: "", data: _vm.orderList },
                },
                _vm._l(_vm.columns2, function (item, tableIndex) {
                  return _c("el-table-column", {
                    key: tableIndex,
                    attrs: {
                      prop: item.prop,
                      label: item.label,
                      width: "",
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              item.prop == "bill_code"
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "underline",
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleCheck(row)
                                        },
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(row[item.prop]) + " ")]
                                  )
                                : _c("div", [
                                    _vm._v(" " + _vm._s(row[item.prop]) + " "),
                                  ]),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  })
                }),
                1
              ),
              _c(
                "div",
                { staticClass: "bottom" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleGoodsSummary },
                    },
                    [_vm._v(" 查看商品汇总 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("check-order", { ref: "checkOrder" }),
      _c("ruilang-display-dialog", {
        ref: "RuilangDisplayDialog",
        attrs: {
          bill_type: "LHZ",
          data_id: 1,
          type: 1,
          "summary-params": _vm.sumdata,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }