<template>
  <!-- NAME[epic=销售] 派单汇总查询 -->
  <div class="orderTest-container">
    <el-form
      ref="form"
      :model="form"
      inline
      label-position="right"
      label-width="80px"
    >
      <el-form-item label="" prop="">
        <el-select
          v-model="form.deliver_id"
          clearable
          style="width: 140px"
          placeholder="请选择司机"
        >
          <el-option
            v-for="(i, idx) in driverSelect"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select
          v-model="form.depot_id"
          clearable
          style="width: 140px"
          placeholder="出货仓库"
        >
          <el-option
            v-for="(i, idx) in depotSelect"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select
          v-model="form.bill_status"
          clearable
          style="width: 140px"
          placeholder="订单状态"
        >
          <el-option
            v-for="(i, idx) in orderStatus"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select
          v-model="form.allot_type"
          clearable
          style="width: 140px"
          placeholder="调拨状态"
        >
          <el-option
            v-for="(i, idx) in transfersStatus"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select
          v-model="form.time_type"
          clearable
          style="width: 140px"
          placeholder="下单时间"
        >
          <el-option
            v-for="(i, idx) in orderTime"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-date-picker
          v-model="time"
          type="datetimerange"
          range-separator="-"
          :default-time="['00:00:00', '23:59:59']"
          value-format="yyyy-MM-dd HH:mm:ss"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button
          :type="btnIndex == 1 ? 'primary' : ''"
          @click="handleGoodsSummary"
        >
          已派商品汇总
        </el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button
          :type="btnIndex == 2 ? 'primary' : ''"
          @click="handleOrderSummary"
        >
          查询已派订单
        </el-button>
      </el-form-item>
      <el-form-item v-if="btnIndex == 1" label="" prop="">
        <el-button type="warning" @click="handleExport(1)">
          导出商品汇总
        </el-button>
        <el-button type="warning" @click="handleExportClient">
          导出客户汇总
        </el-button>
      </el-form-item>
      <el-form-item v-else label="" prop="">
        <el-button type="warning" @click="handleExport(2)">
          导出订单汇总
        </el-button>
      </el-form-item>
    </el-form>
    <div v-loading="loading">
      <div v-show="btnIndex == 1" class="goods">
        <div
          style="
            margin-bottom: 10px;
            font-size: 20px;
            font-weight: 600;
            text-align: center;
          "
        >
          <span v-if="time">{{ time[0] }} 至 {{ time[1] }}</span>
          预订单汇总
        </div>
        <div class="tip">
          <div class="dirver">司机:{{ info.user_name }}</div>
          <div class="car">车辆:{{ info.car_name }}</div>
          <div class="order-count">订单数量:{{ info.order_count }}</div>
          <div class="order-count">总金额:{{ info.total_amount }}</div>
          <div class="money">已收金额:{{ info.already_amount }}</div>
          <div class="wait-money">待收金额:{{ info.left_amount }}</div>
        </div>
        <el-table
          stripe
          :data="list"
          style="margin-bottom: 20px"
          show-summary
          :summary-method="handlersummary"
        >
          <el-table-column
            v-for="(item, tableIndex) in finallyColumns"
            :key="tableIndex"
            :prop="item.prop"
            :label="item.label"
            width=""
            align="center"
          ></el-table-column>
        </el-table>
        <el-table stripe :data="custList">
          <el-table-column
            prop="cust_name"
            label="客户名称"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="address"
            label="地址"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="left_amount"
            label="待收金额"
            align="center"
          ></el-table-column>
        </el-table>
        <div class="bottom">
          <!-- <el-button type="primary">批量打印</el-button>
          <el-button type="primary">导出</el-button> -->
          <el-button type="primary" @click="handleOrderSummary">
            查看订单汇总
          </el-button>
          <el-button type="primary" @click="handleprint">
            打印
          </el-button>
        </div>
      </div>
      <div v-show="btnIndex == 2" v-loading="loading" class="order">
        <div
          style="
            margin-bottom: 10px;
            font-size: 20px;
            font-weight: 600;
            text-align: center;
          "
        >
          <span v-if="time">{{ time[0] }} 至 {{ time[1] }}</span>
          预订单汇总
        </div>
        <div class="tip">
          <div class="dirver">司机:{{ info.user_name }}</div>
          <div class="car">车辆:{{ info.car_name }}</div>
          <div class="order-count">订单数量:{{ info.order_count }}</div>
          <div class="order-count">总金额:{{ info.total_amount }}</div>
          <div class="money">已收金额:{{ info.already_amount }}</div>
          <div class="wait-money">待收金额:{{ info.left_amount }}</div>
        </div>
        <el-table stripe :data="orderList" style="margin-bottom: 10px">
          <el-table-column
            v-for="(item, tableIndex) in columns2"
            :key="tableIndex"
            :prop="item.prop"
            :label="item.label"
            width=""
            align="center"
          >
            <template #default="{ row }">
              <div
                v-if="item.prop == 'bill_code'"
                class="underline"
                @click="handleCheck(row)"
              >
                {{ row[item.prop] }}
              </div>
              <div v-else>
                {{ row[item.prop] }}
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="bottom">
          <!-- <el-button type="primary">批量打印</el-button>
          <el-button type="primary">导出</el-button> -->
          <el-button type="primary" @click="handleGoodsSummary">
            查看商品汇总
          </el-button>

        </div>
      </div>
    </div>
    <check-order ref="checkOrder"></check-order>
    <ruilang-display-dialog
      ref="RuilangDisplayDialog"
      bill_type="LHZ"
      :data_id="1"
      :type="1"
      :summary-params="sumdata"
    ></ruilang-display-dialog>
  </div>
</template>
<script>
  import { driverList, getAllSelect } from '@/api/advanceOrder'
  import { summaryOrder, summaryGoods } from '@/api/saleOrder'
  import _ from 'lodash'
  import { dateForm } from '@/utils/Time.js'
  import checkOrder from '@/views/project/sale/bills/advanceOrder/components/checkOrder/index.vue'
  import { downloadFile, postAction } from '@/api/Employee'
  import RuilangDisplayDialog from "@/baseComponents/ruiLangDesign/ruilangDisplayDialog.vue";
  export default {
    name: 'SummaryQuery',
    components: {RuilangDisplayDialog, checkOrder },
    data() {
      return {
        loading: false,
        time: [],
        btnIndex: 1,
        sumdata:{},
        form: {
          depot_id: '', //仓库ID
          bill_status: '', //状态2已派单3已到货
          allot_type: '', //调拔状态0未调拨1调拨待审2调拨完成
          time_type: '', //时间类型create_at下单时间,delivery_at派送时间
          start_time: '', //开始时间
          end_time: '', //结束时间
          deliver_id: '', //司机ID
        },
        info: {},
        driverSelect: [],
        depotSelect: [],
        // 2已派单3已到货
        orderStatus: [
          {
            id: 2,
            name: '已派单',
          },
          {
            id: 3,
            name: '已到货',
          },
        ],
        // 调拔状态0未调拨1调拨待审2调拨完成
        transfersStatus: [
          {
            id: 0,
            name: '未调拨',
          },
          {
            id: 1,
            name: '调拨待审',
          },
          {
            id: 2,
            name: '调拨完成',
          },
        ],
        // 时间类型create_at下单时间,delivery_at派送时间
        orderTime: [
          {
            id: 'create_at',
            name: '下单时间',
          },
          {
            id: 'delivery_at',
            name: '派单时间',
          },
        ],
        list: [],
        custList: [],
        orderList: [],
        checkList: [
          '商品名称',
          '规格',
          '合计数量（销售+赠品）',
          '销常',
          '赠品',
          '退货',
        ],
        columns: [
          {
            order: 1,
            label: '商品名称',
            prop: 'goods_name',
            width: '',
          },
          {
            order: 2,
            label: '规格',
            prop: 'specs',
            width: '',
          },
          {
            order: 3,
            label: '合计数量（销售+赠品）',
            prop: 'total_num',
            width: '',
          },
          {
            order: 4,
            label: '销常',
            prop: 'goods_num',
            width: '',
          },
          {
            order: 5,
            label: '赠品',
            prop: 'gift_num',
            width: '',
          },
          {
            order: 6,
            label: '退货',
            prop: 'return_num',
            width: '',
          },
        ],
        columns2: [
          {
            order: 1,
            label: '单据',
            prop: 'bill_code',
            width: '',
          },
          {
            order: 2,
            label: '客户名称',
            prop: 'cust_name',
            width: '',
          },
          {
            order: 3,
            label: '订单金额',
            prop: 'total_amount',
            width: '',
          },
          {
            order: 4,
            label: '业务收款',
            prop: 'staff_collection',
            width: '',
          },
          {
            order: 5,
            label: '司机收款',
            prop: 'deliver_collect',
            width: '',
          },
          {
            order: 6,
            label: '状态',
            prop: 'bill_status_text',
            width: '',
          },
          {
            order: 7,
            label: '下单日期',
            prop: 'create_at',
            width: '',
          },
          {
            order: 8,
            label: '打印次数',
            prop: 'print_num',
            width: '',
          },
        ],
        heji_arr: {},
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      time(val) {
        if (val) {
          console.log('timechange')
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    created() {
      console.log('1')
      let now = dateForm(new Date())
      console.log('now', now)

      let now1 = now.split(' ')[0]
      this.time = [now1 + ' 00:00:00', now1 + ' 23:59:59']
      this.form.start_time = now1 + ' 00:00:00'
      this.form.end_time = now1 + ' 23:59:59'
      // 出货仓库下拉
      getAllSelect().then((res) => {
        this.depotSelect = res.data.depot
      })
      // 司机下拉
      driverList().then((res) => {
        this.driverSelect = res.data
      })
    },
    mounted() {
      console.log('2')
      setTimeout(() => {
        this.handleGoodsSummary()
      }, 500)
    },
    activated() {
      if (this.btnIndex == 1) {
        setTimeout(() => {
          this.handleGoodsSummary()
        }, 500)
      } else {
        setTimeout(() => {
          this.handleOrderSummary()
        }, 500)
      }
    },
    methods: {
      handlersummary({ columns, data }) {
        let sums = []
        sums[0] = '总计'
        columns.forEach((list, index) => {
          if (list.label == '合计数量（销售+赠品）') {
            sums[index] = this.heji_arr.goods_heji
          }
          if (list.label == '销常') {
            sums[index] = this.heji_arr.goods_sell
          }
          if (list.label == '赠品') {
            sums[index] = this.heji_arr.goods_gift
          }
          if (list.label == '退货') {
            sums[index] = this.heji_arr.goods_return
          }
        })
        return sums
      },
      handleprint(){
        this.$refs.RuilangDisplayDialog.dialogFormVisible = true
      },
      async handleGoodsSummary() {
        console.log('handldadada')
        this.loading = true
        try {
          let { data } = await summaryGoods(this.form)
          console.log(this.sumdata,'sumdata')
          this.info = data.info
          if (data.heji_arr) {
            console.log(data, '合计')
            this.heji_arr = data.heji_arr
          }
          this.sumdata={
            depot_id:data.depot_ids,
            is_more:1,
            order_ids:data.order_ids,
          }
          this.list = data.goods_list
          this.custList = data.cust_list
          this.btnIndex = 1
          this.loading = false
        } catch (e) {
          this.loading = false
        }
      },
      async handleOrderSummary() {
        this.loading = true

        let { data } = await summaryOrder(this.form)
        this.info = data.info

        this.orderList = data.list
        this.btnIndex = 2

        this.loading = false
      },
      handleCheck(row) {
        this.$refs['checkOrder'].showDialog()
        this.$refs['checkOrder'].orderStatus2 = row.bill_status_text
        // this.$refs['checkOrder'].orderStatus = row.bill_status_text
        this.$refs['checkOrder'].id = row.id
      },
      handleExportClient() {
        // saleAdmin/order-pre/delivery-goods-record-export2
        postAction(
          'saleAdmin/order-pre/delivery-goods-record-export2',
          this.form
        ).then((r) => {
          downloadFile(
            'saleAdmin/order-pre/delivery-goods-record-export2',
            '客户汇总.xlsx',
            this.form
          )
        })
      },
      handleExport(type) {
        let url = '',
          name = ''
        if (type == 1) {
          url = '/saleAdmin/order-pre/delivery-goods-record-export'
          name = '商品汇总.xlsx'
        } else {
          url = '/saleAdmin/order-pre/delivery-order-record-export'
          name = '订单汇总.xlsx'
        }

        postAction(url, this.form).then((r) => {
          downloadFile(url, name, this.form)
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .tip {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  .bottom {
    margin-top: 15px;
  }
</style>
